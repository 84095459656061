const CONFIG = {
  firebaseConfig: {
    apiKey: 'AIzaSyBXPTJTh1ZFCrg9NejZDy7avllV2g5DF9o',
    authDomain: 'oyeapp-79699.firebaseapp.com',
    projectId: 'oyeapp-79699',
    storageBucket: 'oyeapp-79699.appspot.com',
    messagingSenderId: '775714586523',
    appId: '1:775714586523:web:1177fc26b376cbca072b3e',
    measurementId: 'G-QC1YTHFYQ7',
  },
  domain: 'https://agent.oye-app.com', //production
  appVersion: '20230522-2320',
}
// domain: "https://agent.oye-app.com", //production
//domain: "http://localhost:8100", //development

export default CONFIG
