import create from 'zustand'
import { doc, getFirestore, query, onSnapshot, orderBy } from 'firebase/firestore'
import { COLLECTIONS, gasStationsCollectionRef } from '../services/firebase'

import _ from 'lodash'

const useStore = create((set, get) => ({
  user: null,
  isAuthDataLoaded: false,
  gasStations: [],
  customers: [],
  defaultCountryCode: 'KE',

  gastStationsQueryListener: () => {
    console.log('Unsibscribe on CUSTOMERS listerner called but not set yet')
  },
  userQueryListener: () => {
    console.log('Unsibscribe on current user listerner called but not set yet')
  },

  /**
   * Load all Gas stations and listen for changes
   */
  loadGasStations: () => {
    const gasStationsQuery = query(gasStationsCollectionRef, orderBy('name', 'desc'))
    const unsubscribe = onSnapshot(gasStationsQuery, querySnapshot => {
      const gasStations = []
      querySnapshot.forEach(doc => {
        gasStations.push({ ...doc.data(), id: doc.id })
      })
      set({ gasStations })
    })
    set({ customersQueryListener: unsubscribe })
  },

  getGasStationById: gasStationId => {
    return _.find(get().gasStations, { id: gasStationId })
  },

  /**
   * INITIAL LOAD
   * @param {*} userId
   *
   */
  loadUserAndOrganization: async userId => {
    if (!userId) {
      return
    }

    const db = getFirestore()
    const userRef = doc(db, COLLECTIONS.users, userId)

    const unsubscribe = onSnapshot(userRef, userSnap => {
      if (userSnap.exists()) {
        const userData = userSnap.data()

        set({
          user: { ...userData, id: userSnap.id },
          isAuthDataLoaded: true,
        })

        get().loadGasStations()
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!')
      }
    })

    set({ userQueryListener: unsubscribe })
  },

  /**
   * CLEANUP
   */
  unsubscribeListeners: () => {
    console.log('Store unsunbscribing from listeners')

    get().gastStationsQueryListener()
    get().userQueryListener()
  },
}))

export default useStore
