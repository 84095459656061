import { useMemo, useState } from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonLabel,
  IonItem,
  IonButtons,
  IonButton,
  IonIcon,
  useIonModal,
  useIonLoading,
  IonInput,
  IonItemDivider,
  IonSearchbar,
  IonText,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonListHeader,
  useIonToast,
} from '@ionic/react'
import { close, phonePortraitOutline, locationOutline, cashOutline } from 'ionicons/icons'

import { serverTimestamp, updateDoc, doc } from 'firebase/firestore'

import { useForm } from 'react-hook-form'

import Fuse from 'fuse.js'

import _ from 'lodash'
import { formatPhoneNumberIntl } from 'react-phone-number-input/mobile'

import { gasStationsCollectionRef, usersCollectionRef } from '../services/firebase'
import useStore from '../store/useStore'

const locale = Intl.NumberFormat('ke-KE', {
  style: 'currency',
  currency: 'KES',
  maximumFractionDigits: 2,
})

const EditCurrentFuelPrice = ({ onDismiss, onSave, currentFuelPrice }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { currentFuelPrice: currentFuelPrice } }) // {defaultValues: product}
  const onSubmit = data => {
    onSave({ ...data })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="secondary">
              <IonButton
                color="secondary"
                onClick={() => {
                  onDismiss({})
                }}
              >
                <IonIcon slot="icon-only" icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle>Edit fuel price</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonItemDivider />

          <IonItem>
            <IonLabel color={errors.currentFuelPrice ? 'danger' : undefined} position="stacked">
              Current fuel price at this station
            </IonLabel>
            <IonInput
              {...register('currentFuelPrice', { required: false, min: 0, valueAsNumber: true })}
              type="decimal"
              placeholder="Fuel price"
              inputmode="decimal"
            ></IonInput>
          </IonItem>

          <IonItemDivider />
          <IonButton expand="block" type="submit">
            Save Changes
          </IonButton>
        </IonContent>
      </IonPage>
    </form>
  )
}

const GasStations = () => {
  const user = useStore(state => state.user)
  const gasStations = useStore(state => state.gasStations)
  const getGasStationById = useStore(state => state.getGasStationById)
  const [searchText, setSearchText] = useState()

  const searchObj = useMemo(() => {
    return new Fuse(gasStations, {
      keys: ['name', 'address'],
      minMatchCharLength: 3,
      threshold: 0.3,
    })
  }, [gasStations])

  const foundGasStations = useMemo(() => {
    if (searchText) {
      const results = searchObj.search(searchText)

      return results.map(el => el.item)
    } else {
      return gasStations
    }
  }, [searchText, searchObj, gasStations])

  const currentGasStation = useMemo(() => {
    if (user && gasStations?.length > 0 && user.currentGasStationId) {
      return getGasStationById(user.currentGasStationId)
    }
    return null
  }, [user, getGasStationById, gasStations])

  const handleEditCurrentPriceModalDismiss = () => {
    dismissEditModal()
  }

  const handleEditCurrentPrice = async editedData => {
    if (!currentGasStation) {
      return
    }
    try {
      presentLoader({
        message: 'Saving...',
      })
      let gasStationRef = doc(gasStationsCollectionRef, currentGasStation?.id)
      await updateDoc(gasStationRef, {
        currentFuelPrice: editedData.currentFuelPrice,
        currentFuelPriceUpdatedAt: serverTimestamp(),
        currentFuelPriceUpdatedBy: user.id,
      })

      setTimeout(() => {
        dismissLoader()
      }, 250)
      handleEditCurrentPriceModalDismiss()
      setTimeout(() => {
        presentToast({
          color: 'success',
          message: 'Changes saved successfully',
          duration: 2000,
        })
      }, 500)
    } catch (error) {
      dismissLoader()
      setTimeout(() => {
        presentToast({
          color: 'danger',
          message: 'Failed to save changes. Please try again.',
          duration: 2000,
        })
      }, 500)
    }
  }

  const setGasStationAsDefault = async gasStationId => {
    if (!gasStationId || !user?.id) {
      return
    }
    try {
      presentLoader({
        message: 'Saving...',
      })
      let meRef = doc(usersCollectionRef, user.id)
      await updateDoc(meRef, {
        currentGasStationId: gasStationId,
      })

      dismissLoader()
      setTimeout(() => {
        presentToast({
          color: 'success',
          message: 'Current station updated',
          duration: 2000,
        })
      }, 500)
    } catch (error) {
      dismissLoader()
      setTimeout(() => {
        presentToast({
          color: 'danger',
          message: 'Failed to save changes. Please try again.',
          duration: 2000,
        })
      }, 500)
    }
  }

  const [presentToast] = useIonToast()

  const [presentLoader, dismissLoader] = useIonLoading()

  const [presentEditCurrentFuelPrice, dismissEditModal] = useIonModal(EditCurrentFuelPrice, {
    onDismiss: handleEditCurrentPriceModalDismiss,
    onSave: handleEditCurrentPrice,
    currentFuelPrice: currentGasStation?.currentFuelPrice || 0,
  })

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Gas stations</IonTitle>
        </IonToolbar>

        <IonToolbar>
          <IonSearchbar value={searchText} onIonChange={e => setSearchText(e?.detail?.value)} animated></IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {currentGasStation && (
          <IonCard>
            <IonCardHeader>
              <IonCardSubtitle>Current gas station</IonCardSubtitle>
              <IonCardTitle>{currentGasStation?.name}</IonCardTitle>
            </IonCardHeader>
            <IonItem lines="none">
              <IonIcon icon={cashOutline} slot="start" />
              <IonLabel className="ion-text-wrap">
                <IonText>
                  <p>Current fuel price</p>
                  <h4 className="bold">{locale.format(currentGasStation.currentFuelPrice)}/l</h4>
                </IonText>
              </IonLabel>
              <IonButton
                onClick={() => {
                  presentEditCurrentFuelPrice()
                }}
              >
                Update
              </IonButton>
            </IonItem>

            <IonItem lines="none">
              <IonIcon icon={locationOutline} slot="start" />
              <IonLabel className="ion-text-wrap">
                <IonText>
                  <p>Address</p>
                  <h4>{currentGasStation?.address}</h4>
                </IonText>
              </IonLabel>
            </IonItem>

            <IonItem lines="none">
              <IonIcon icon={phonePortraitOutline} slot="start" />
              <IonLabel className="ion-text-wrap">
                <IonText>
                  <p>Phone number</p>
                  <h4>{currentGasStation?.phone ? formatPhoneNumberIntl(currentGasStation?.phone) : ''}</h4>
                </IonText>
              </IonLabel>
            </IonItem>
          </IonCard>
        )}

        <IonList lines="inset">
          {foundGasStations?.map(gasStation => (
            <IonItem
              key={gasStation.id}
              onClick={() => {
                // presentEditModal()
              }}
            >
              <IonLabel className="ion-text-wrap">
                <IonText color="dark">
                  <h2>{gasStation.name}</h2>
                </IonText>
                <IonText color="medium">
                  <h3>{formatPhoneNumberIntl(gasStation?.phone)}</h3>
                  <h3>{gasStation.address}</h3>
                </IonText>
                <IonText color="primary">
                  <h4 className="bold">{locale.format(gasStation.currentFuelPrice)}/l</h4>
                </IonText>
              </IonLabel>

              <IonButton
                slot="end"
                disabled={currentGasStation?.id && gasStation.id === currentGasStation.id ? true : false}
                onClick={() => {
                  setGasStationAsDefault(gasStation.id)
                }}
              >
                {currentGasStation?.id && gasStation.id === currentGasStation.id ? 'Default' : 'Set as default'}
              </IonButton>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  )
}

export default GasStations
