import { useCallback, useMemo, useState, useEffect } from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonItem,
  IonSearchbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonItemDivider,
  IonInput,
  useIonModal,
  useIonToast,
  IonText,
  IonLoading,
} from '@ionic/react'
import { add, close } from 'ionicons/icons'
import { query, where, addDoc, serverTimestamp, getDocs } from 'firebase/firestore'
import { Virtuoso } from 'react-virtuoso'

import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import { isValidPhoneNumber, getCountryCallingCode, formatPhoneNumberIntl } from 'react-phone-number-input/mobile'

import 'react-phone-number-input/style.css'

// import './Customers.css'

import { useForm } from 'react-hook-form'

import { customersCollectionRef } from '../services/firebase'

import useStore from '../store/useStore'
import useCustomerStore from '../store/useCustomerStore'

const AddCustomerModal = ({ onDismiss, onSave, isSaving }) => {
  const defaultCountryCode = useStore(store => store.defaultCountryCode)
  /**
   * how do we set error message for the errors obj form formState?
   *
   * we should show activness of forms, like when the cursor is there
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onTouched',
    // reValidateMode: 'onChange',
    // shouldFocusError: true,
    // shouldUseNativeValidation: true,
    // delayError: true,
  })

  const onSubmit = data => {
    if (isSaving) {
      return
    }
    if (isValidPhoneNumber(data.phone)) {
      onSave({
        ...data,
        phoneCountryCode: phoneCountryValue,
        phoneCountryCallingCode: getCountryCallingCode(phoneCountryValue),
      })
    }
  }

  const [phoneCountryValue, setPhoneCountryValue] = useState(defaultCountryCode)

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="secondary">
            <IonButton
              color="secondary"
              onClick={() => {
                onDismiss({})
              }}
            >
              <IonIcon slot="icon-only" icon={close} />
            </IonButton>
          </IonButtons>
          <IonTitle>Add Customer</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonItemDivider />

          <IonItem>
            <IonLabel color={errors.phone ? 'danger' : undefined} position="stacked">
              Phone number
            </IonLabel>

            <PhoneInputWithCountry
              name="phone"
              placeholder="Phone number"
              defaultCountry={phoneCountryValue}
              limitMaxLength={true}
              onCountryChange={setPhoneCountryValue}
              control={control}
              rules={{ required: true, validate: isValidPhoneNumber }}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked" color={errors.firstName ? 'danger' : undefined}>
              First name
            </IonLabel>
            <IonInput
              required
              {...register('firstName', { required: true })}
              placeholder="First name"
              autocomplete="off"
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked" color={errors.lastName ? 'danger' : undefined}>
              Last name
            </IonLabel>
            <IonInput
              required
              {...register('lastName', { required: true })}
              placeholder="Last name"
              autocomplete="off"
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked" color={errors.officialId ? 'danger' : undefined}>
              Official ID
            </IonLabel>
            <IonInput
              required
              {...register('officialId', { required: true })}
              placeholder="ID"
              autocomplete="off"
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked" color={errors.sacco ? 'danger' : undefined}>
              Sacco name
            </IonLabel>
            <IonInput
              required
              {...register('sacco', { required: false })}
              placeholder="Sacco"
              autocomplete="off"
            ></IonInput>
          </IonItem>
          {/* <IonItem>
            <IonLabel position="stacked" color={errors.address ? 'danger' : undefined}>
              Address
            </IonLabel>
            <IonTextarea
              {...register('address', { required: true })}
              placeholder="Full Address"
              autocomplete="off"
              required
            ></IonTextarea>
          </IonItem> */}
          <IonItemDivider />
          <IonButton expand="block" type="submit" disabled={isSaving}>
            {isSaving ? 'Saving....' : 'Save Customer'}
          </IonButton>
        </form>
      </IonContent>
    </IonPage>
  )
}

const Customers = () => {
  const user = useStore(state => state.user)
  const { customers, findCustomerByPhoneOrName } = useCustomerStore()
  const [searchText, setSearchText] = useState()
  const [saving, setSaving] = useState(false)

  const foundCustomers = useMemo(() => {
    if (searchText && searchText.length > 4 && customers.length > 0) {
      return findCustomerByPhoneOrName(searchText)
    }
    return []
  }, [findCustomerByPhoneOrName, searchText, customers])

  const getCustomer = useCallback(
    index => {
      return foundCustomers[index]
    },
    [foundCustomers]
  )

  const handleSave = async data => {
    setSaving(true)
    const customersWithPhoneQuery = query(customersCollectionRef, where('phone', '==', data.phone))
    const querySnapshot = await getDocs(customersWithPhoneQuery)
    if (querySnapshot.docs.length > 0) {
      presentToast({
        color: 'danger',
        message: 'Customer with phone no. exists',
        duration: 2000,
        position: 'bottom',
      })
    } else {
      try {
        await addDoc(customersCollectionRef, {
          ...data,
          fullName: `${data.firstName} ${data.lastName}`,
          phoneCallingCode: getCountryCallingCode(data.phoneCountryCode),
          createdAt: serverTimestamp(),
          createdBy: user?.id,
        })
        handleDismiss()
        setTimeout(() => {
          presentToast({
            color: 'success',
            message: 'Customer added successfully',
            duration: 2000,
          })
        }, 500)
      } catch (err) {
        presentToast({
          color: 'danger',
          message: 'Error, please try again. Check the network',
          duration: 2000,
          position: 'bottom',
        })
      } finally {
        setSaving(false)
      }
    }

    setSaving(false)
  }

  const handleDismiss = () => {
    dismiss()
  }

  // handle loader flow in modal ?
  const [present, dismiss] = useIonModal(AddCustomerModal, {
    onDismiss: handleDismiss,
    onSave: handleSave,
    isSaving: saving,
  })

  const [presentToast] = useIonToast()

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Customers</IonTitle>
          <IonButton
            slot="end"
            size="small"
            fill="solid"
            shape="round"
            color="tertiary"
            onClick={() => {
              present({})
            }}
          >
            <IonIcon color="secondary" icon={add} />
            <IonText color="secondary" className="top-right-button">
              Add
            </IonText>
          </IonButton>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            placeholder="Search by phone number"
            value={searchText}
            onIonChange={e => setSearchText(e?.detail?.value)}
            animated
          ></IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <Virtuoso
          style={{ height: '100%' }}
          totalCount={foundCustomers ? foundCustomers.length : 0}
          itemContent={index => {
            return (
              // <div style={{ height: '100%' }}>
              <IonItem routerLink={`/app/customers/${getCustomer(index).id}`}>
                <IonLabel>
                  <h2>{getCustomer(index).fullName}</h2>
                  <h4>{formatPhoneNumberIntl(getCustomer(index).phone)}</h4>
                </IonLabel>
              </IonItem>
              // </div>
            )
          }}
        />
        {/* <IonList>
          {foundCustomers?.map(customer => (
            <IonItem key={customer.id} routerLink={`/app/customers/${customer.id}`}>
              <IonLabel>
                <h2>{customer.fullName}</h2>
                <h4>{formatPhoneNumberIntl(customer.phone)}</h4>
              </IonLabel>
            </IonItem>
          ))}
        </IonList> */}
        <IonLoading isOpen={!customers || customers.length === 0} message={'Loading customers...'} />
      </IonContent>
    </IonPage>
  )
}

export default Customers
