import { useState, useCallback } from 'react'
import {
  IonCard,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonText,
} from '@ionic/react'
import { sendSignInLinkToEmail } from 'firebase/auth'
import { useForm } from 'react-hook-form'
import './Login.css'

import { auth, doesUserExistFBFunction } from '../services/firebase'
import CONFIG from '../config/config'

/**
 * Login and Sign up screen... ?
 * TODO: display message for error scenrios. if the link is expired, or used, etc.
 * @returns
 */
const Login = () => {
  const [isWorking, setIsWorking] = useState(false)
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [errorMsg, setErrorMsg] = useState()
  const { register, handleSubmit } = useForm()

  const sendEmailSignInLink = useCallback(async data => {
    try {
      setIsWorking(true)
      setErrorMsg()

      const result = await doesUserExistFBFunction({ email: data.email.toLowerCase().trim() })
      const { userExists } = result.data

      if (userExists) {
        const actionCodeSettings = {
          url: `${CONFIG.domain}/sign-in-with-email-link/`,
          handleCodeInApp: true,
        }

        await sendSignInLinkToEmail(auth, data.email, actionCodeSettings)

        window.localStorage.setItem('emailForSignIn', data.email)

        setIsEmailSent(true)

        setIsWorking(false)
      } else {
        setErrorMsg('There is no user with that email. Please make sure the email is correct.')
        setIsWorking(false)
      }
    } catch (err) {
      setErrorMsg(err.message)
      setIsWorking(false)
    }
  }, [])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-align-items-center" fullscreen>
        <div className="wrapper">
          <form className="formWrapper" onSubmit={handleSubmit(sendEmailSignInLink)} method="POST">
            <IonCard>
              <IonCardHeader className="center-text">
                <IonCardTitle>Sign-in to Oye Manager App</IonCardTitle>
                <h6>We'll send a magic link to your email. Click on that link to sign-in.</h6>
              </IonCardHeader>
              <IonCardContent>
                <IonItem color={errorMsg ? 'danger' : undefined}>
                  <IonLabel position="stacked">E-mail</IonLabel>
                  <IonInput
                    placeholder="Your email address"
                    disabled={isEmailSent}
                    inputMode="email"
                    type="email"
                    required
                    {...register('email', { required: true })}
                  />
                </IonItem>
                {!isEmailSent && (
                  <>
                    <IonButton expand="block" type="submit" disabled={isWorking}>
                      {isWorking ? 'Working...' : 'Generate Sign-in link'}
                    </IonButton>

                    {errorMsg && <IonText color="danger">{errorMsg}</IonText>}
                  </>
                )}
              </IonCardContent>
            </IonCard>
          </form>
          {isEmailSent && (
            <IonCard className="link-sent">
              <IonCardHeader>
                <IonCardTitle>Link sent</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>Check your inbox and just click the link to sign-in</IonCardContent>
            </IonCard>
          )}
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Login
