import { useCallback, useMemo, useState, Fragment, useContext } from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonLabel,
  IonItem,
  IonButtons,
  IonBackButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonText,
  IonListHeader,
  IonButton,
  useIonModal,
  IonIcon,
  IonChip,
  IonInput,
  IonCardSubtitle,
  IonItemDivider,
  useIonToast,
  NavContext,
} from '@ionic/react'

import { query, where, addDoc, serverTimestamp, orderBy, doc } from 'firebase/firestore'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import { formatPhoneNumberIntl } from 'react-phone-number-input/mobile'
import _ from 'lodash'
import {
  cashOutline,
  idCardOutline,
  phonePortraitOutline,
  calendarClearOutline,
  calendarOutline,
  storefrontOutline,
} from 'ionicons/icons'
import { useParams } from 'react-router'
import { DateTime } from 'luxon'

import { transactionsCollectionRef, balancesCollectionRef, policiesCollectionRef } from '../services/firebase'
import useStore from '../store/useStore'
import useCustomerStore from '../store/useCustomerStore'
// import AddTransactionForm from '../components/AddTransactionForm'
import { defaultTimezone } from '../constants/constants'

const DATE_DISPLAY_FORMAT = 'd LLL, yyyy'

const locale = Intl.NumberFormat('ke-KE', {
  style: 'currency',
  currency: 'KES',
  maximumFractionDigits: 2,
})

const litresLocale = Intl.NumberFormat('ke-KE', {
  style: 'unit',
  unit: 'liter',
  unitDisplay: 'long',
})

const PolicyCard = ({ policy }) => {
  const startDateDisplay = useMemo(() => {
    const parsedDate = DateTime.fromJSDate(policy.startDate.toDate()).setZone(defaultTimezone)
    return parsedDate.toFormat(DATE_DISPLAY_FORMAT)
  }, [policy.startDate])

  const endDateDisplay = useMemo(() => {
    const parsedDate = DateTime.fromJSDate(policy.endDate.toDate()).setZone(defaultTimezone)
    return parsedDate.toFormat(DATE_DISPLAY_FORMAT)
  }, [policy.endDate])

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardSubtitle>{policy?.policyNumber}</IonCardSubtitle>
      </IonCardHeader>
      <IonItem lines="none">
        <IonIcon icon={calendarClearOutline} slot="start" />
        <IonLabel className="ion-text-wrap">
          <IonText>
            <p>Start date</p>
            <h4 className="bold">{startDateDisplay}</h4>
          </IonText>
        </IonLabel>
      </IonItem>
      <IonItem lines="none">
        <IonIcon icon={calendarOutline} slot="start" />
        <IonLabel className="ion-text-wrap">
          <IonText>
            <p>End date</p>
            <h4 className="bold">{endDateDisplay}</h4>
          </IonText>
        </IonLabel>
      </IonItem>
    </IonCard>
  )
}

const TransactionCard = ({ transaction, gasStation, totalAmount }) => {
  return (
    <IonItem key={transaction?.id}>
      <IonIcon icon={cashOutline} slot="start" />
      <IonLabel className="ion-text-wrap">
        <IonText>
          <p>Fuel / Transaction amount</p>
          <h4>
            {transaction?.fuelAmount}l / {locale.format(transaction?.amount)}
          </h4>
        </IonText>
      </IonLabel>
    </IonItem>
  )
}

const AddTransactionForm = ({ onSave, fuelPrice, updateTrigger, saving }) => {
  const [amount, setAmount] = useState(0)

  const [presentToast] = useIonToast()

  const fuelAmount = useMemo(() => {
    if (amount > 0) {
      return parseFloat((amount / fuelPrice).toFixed(2))
    }
    return 0
  }, [amount, fuelPrice])

  const handleSave = useCallback(() => {
    if (saving) {
      return
    }
    if (amount > 0) {
      onSave({ amount, fuelAmount, fuelPrice })
    } else {
      presentToast({
        color: 'danger',
        message: `Amount can't be 0`,
        duration: 1000,
      })
    }
  }, [amount, fuelAmount, fuelPrice, onSave, presentToast, saving])

  const errors = {}
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Add transaction</IonCardTitle>
        <IonCardSubtitle>It'll be added for the current gas station</IonCardSubtitle>
      </IonCardHeader>

      <IonItem>
        <IonLabel position="stacked" color={errors.amount ? 'danger' : undefined}>
          Transaction amount in KES
        </IonLabel>
        <IonInput
          required
          name="amount"
          type="number"
          value={amount}
          onIonChange={e => {
            setAmount(e.detail.value ? parseFloat(parseFloat(e.detail.value).toFixed(2)) : 0)
          }}
          placeholder="Amount"
          autocomplete="off"
        />
      </IonItem>

      <IonItem lines="none">
        <IonText>
          <p>Amount of fuel calculated by current gas price</p>
          <h4>
            {fuelAmount} litres at {locale.format(fuelPrice)}/l
          </h4>
        </IonText>
      </IonItem>
      <IonCardContent>
        <IonButton
          expand="block"
          type="submit"
          disabled={saving}
          onClick={() => {
            handleSave()
          }}
        >
          {saving ? 'Saving...' : 'Save transaction'}
        </IonButton>
      </IonCardContent>
    </IonCard>
  )
}

const CustomerDetails = () => {
  const user = useStore(state => state.user)
  const getCustomerById = useCustomerStore(state => state.getCustomerById)
  const getGasStationById = useStore(state => state.getGasStationById)
  const customers = useCustomerStore(state => state.customers)
  const gasStations = useStore(state => state.gasStations)
  const [saving, setSaving] = useState()
  const { customerId } = useParams()
  const [balance] = useDocumentData(doc(balancesCollectionRef, customerId), { idField: 'id' })

  const { navigate } = useContext(NavContext)

  const customer = useMemo(() => {
    if (customers.length) {
      return getCustomerById(customerId)
    }
    return null
  }, [getCustomerById, customerId, customers])

  const policiesQuery = useMemo(() => {
    //? query(policiesCollectionRef, where('customerId', '==', customer.id), orderBy('endAt', 'desc'))
    return customer?.id
      ? query(policiesCollectionRef, where('customerId', '==', customer.id), orderBy('endDate', 'desc'))
      : undefined
  }, [customer?.id])

  const [policies, arePoliciesLoading] = useCollectionData(policiesQuery, {
    idField: 'id',
  })

  const currentGasStation = useMemo(() => {
    if (user && gasStations?.length > 0 && user.currentGasStationId) {
      return getGasStationById(user.currentGasStationId)
    }
    return null
  }, [user, getGasStationById, gasStations])

  const transactionsQuery = useMemo(() => {
    return customer?.id
      ? query(transactionsCollectionRef, where('customerId', '==', customer.id), orderBy('createdAt', 'desc'))
      : undefined
  }, [customer?.id])

  const [transactions, areTransactionsLoading] = useCollectionData(transactionsQuery, {
    idField: 'id',
  })

  const [presentToast] = useIonToast()

  const handleSave = async data => {
    if (!currentGasStation || !customer) {
      presentToast({
        color: 'danger',
        message: 'Default gas station is not selected',
        duration: 2000,
      })
      return
    }

    try {
      setSaving(true)

      await addDoc(transactionsCollectionRef, {
        ...data,
        customerId: customer?.id,
        createdAt: serverTimestamp(),
        createdBy: user?.id,
        gasStationId: currentGasStation.id,
      })

      setTimeout(() => {
        presentToast({
          color: 'success',
          message: 'Transaction added successfully',
          duration: 2000,
        })
        navigate('/app/customers/', 'back')
      }, 300)
    } catch (err) {
      presentToast({
        color: 'danger',
        message: 'Something went wrong, please try again',
        duration: 2000,
      })
    } finally {
      setSaving(false)
    }
  }

  const groupedTransactions = useMemo(() => {
    return _.groupBy(transactions, el => {
      return el?.createdAt?.toDate()?.toLocaleDateString()
    })
  }, [transactions])

  const totalFuelAmount = useMemo(() => {
    if (!transactions) {
      return 0
    } else {
      const calculatedValue = transactions?.reduce((total, transaction) => {
        total = total + transaction.fuelAmount
        return total
      }, 0)
      return calculatedValue.toFixed(2)
    }
  }, [transactions])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Customer Details</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <AddTransactionForm fuelPrice={currentGasStation?.currentFuelPrice} onSave={handleSave} saving={saving} />
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{customer?.fullName}</IonCardTitle>
          </IonCardHeader>
          <IonItem lines="none">
            <IonIcon icon={phonePortraitOutline} slot="start" />
            <IonLabel className="ion-text-wrap">
              <IonText>
                <p>Phone number</p>
                <h4>{customer?.phone ? formatPhoneNumberIntl(customer?.phone) : ''}</h4>
              </IonText>
            </IonLabel>
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={idCardOutline} slot="start" />
            <IonLabel className="ion-text-wrap">
              <IonText>
                <p>Official Id</p>
                <h4>{customer?.officialId}</h4>
              </IonText>
            </IonLabel>
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={storefrontOutline} slot="start" />
            <IonLabel className="ion-text-wrap">
              <IonText>
                <p>Sacco</p>
                <h4>{customer?.sacco ? customer?.sacco : '---'}</h4>
              </IonText>
            </IonLabel>
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={cashOutline} slot="start" />
            <IonLabel className="ion-text-wrap">
              <IonText>
                <p>Current balance</p>
                <h4 className="bold">{balance ? litresLocale.format(balance.currentFuelBalance) : 'Loading...'}</h4>
              </IonText>
            </IonLabel>
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={cashOutline} slot="start" />
            <IonLabel className="ion-text-wrap">
              <IonText>
                <p>Total fuel purchased</p>
                <h4 className="bold">{totalFuelAmount} litres</h4>
              </IonText>
            </IonLabel>
          </IonItem>
        </IonCard>

        <IonListHeader mode="ios" className="has-add-list-header center-wrapper">
          <IonLabel>{arePoliciesLoading ? 'Loading policies...' : `Insurance policies`}</IonLabel>
        </IonListHeader>
        <div className="list-wrapper">
          <IonList>
            {policies?.map(policy => (
              <PolicyCard key={policy.id} policy={policy} />
            ))}
          </IonList>
        </div>

        <IonListHeader mode="ios" className="has-add-list-header center-wrapper">
          <IonLabel>{areTransactionsLoading ? 'Loding transactions...' : 'Transactions'}</IonLabel>
        </IonListHeader>
        <div className="list-wrapper">
          <IonList>
            {Object.keys(groupedTransactions).map(group => (
              <Fragment key={group}>
                <IonListHeader lines="inset" key={group}>
                  <IonLabel color="primary">{group}</IonLabel>
                </IonListHeader>
                {groupedTransactions[group]?.map(transaction => (
                  <TransactionCard
                    key={transaction.id}
                    transaction={transaction}
                    gasStation={getGasStationById(transaction.gasStationId)}
                  />
                ))}
              </Fragment>
            ))}
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default CustomerDetails
