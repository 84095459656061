import { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'

import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react'

import { cashOutline, peopleOutline, settingsOutline, storefrontOutline } from 'ionicons/icons'

// import Organizations from './pages/Organizations'
import Account from './pages/Account'
import Customers from './pages/Customers'
import CustomerDetails from './pages/CustomerDetails'
import GasStations from './pages/GasStations'
import Transactions from './pages/Transactions'

import useCustomerStore from './store/useCustomerStore'

const Tabs = () => {
  const { initialize } = useCustomerStore()
  useEffect(() => {
    const cleanup = initialize()

    // Clean up the Firestore listener on component unmount
    return () => {
      cleanup()
    }
  }, [initialize])

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route exact path="/app/transactions">
          <Transactions />
        </Route>

        <Route exact path="/app/stations">
          <GasStations />
        </Route>
        <Route path="/app/customers/:customerId">
          <CustomerDetails />
        </Route>
        <Route exact path="/app/customers">
          <Customers />
        </Route>

        <Route path="/app/settings">
          <Account />
        </Route>

        <Route exact path="/app">
          <Redirect to="/app/stations" />
        </Route>
      </IonRouterOutlet>

      <IonTabBar slot="bottom">
        <IonTabButton tab="customers" href="/app/customers">
          <IonIcon icon={peopleOutline} />
          <IonLabel>Customers</IonLabel>
        </IonTabButton>
        <IonTabButton tab="stations" href="/app/stations">
          <IonIcon icon={storefrontOutline} />
          <IonLabel>Gas stations</IonLabel>
        </IonTabButton>
        <IonTabButton tab="transactions" href="/app/transactions">
          <IonIcon icon={cashOutline} />
          <IonLabel>Your Transactions</IonLabel>
        </IonTabButton>
        <IonTabButton tab="settings" href="/app/settings">
          <IonIcon icon={settingsOutline} />
          <IonLabel>Settings</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  )
}

export default Tabs
