import { useCallback, useMemo } from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonItem,
  IonCard,
  IonCardHeader,
  IonText,
  IonListHeader,
  IonIcon,
  IonCardSubtitle,
  IonLoading,
  IonCardContent,
  IonCardTitle,
} from '@ionic/react'

import { query, where, orderBy, Timestamp } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { formatPhoneNumberIntl } from 'react-phone-number-input/mobile'
import _ from 'lodash'
import { cashOutline, funnelOutline, personOutline, storefrontOutline } from 'ionicons/icons'
import { GroupedVirtuoso } from 'react-virtuoso'
import { transactionsCollectionRef } from '../services/firebase'
import useStore from '../store/useStore'
import useCustomerStore from '../store/useCustomerStore'
import { DateTime } from 'luxon'

// import AddTransactionForm from '../components/AddTransactionForm'

const locale = Intl.NumberFormat('ke-KE', {
  style: 'currency',
  currency: 'KES',
  maximumFractionDigits: 2,
})

// const TransactionCard = ({ transaction, gasStation, customer }) => {
//   return (
//     <IonItem key={transaction?.id}>
//       <IonIcon icon={cashOutline} slot="start" />
//       <IonLabel className="ion-text-wrap">
//         <IonText>
//           <p>Fuel / Transaction amount</p>
//           <h4>
//             {transaction?.fuelAmount}l / {locale.format(transaction?.amount)}
//           </h4>
//         </IonText>
//       </IonLabel>

//       <IonLabel className="ion-text-wrap">
//         <IonText>
//           <p>{customer?.fullName}</p>
//           <h4>{customer?.phone ? formatPhoneNumberIntl(customer?.phone) : ''}</h4>
//         </IonText>
//       </IonLabel>
//     </IonItem>
//   )
// }

const TransactionCard = ({ transaction, gasStation, customer }) => {
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardSubtitle color="primary">{transaction.createdAt?.toDate()?.toLocaleTimeString()}</IonCardSubtitle>
      </IonCardHeader>
      <IonItem lines="none">
        <IonIcon icon={personOutline} slot="start" />
        <IonLabel className="ion-text-wrap">
          <IonText>
            <p>{formatPhoneNumberIntl(customer?.phone)}</p>
            <h4 className="bold">{customer.fullName}</h4>
          </IonText>
        </IonLabel>
      </IonItem>
      <IonItem lines="none">
        <IonIcon icon={cashOutline} slot="start" />
        <IonLabel className="ion-text-wrap">
          <IonText>
            <p>Amount</p>
            <h4 className="bold">{locale.format(transaction?.amount)}</h4>
          </IonText>
        </IonLabel>
      </IonItem>
      <IonItem lines="none">
        <IonIcon icon={funnelOutline} slot="start" />
        <IonLabel className="ion-text-wrap">
          <IonText>
            <p>Fuel amount</p>
            <h4 className="bold">{transaction?.fuelAmount} litres</h4>
          </IonText>
        </IonLabel>
      </IonItem>
      <IonItem lines="none">
        <IonIcon icon={storefrontOutline} slot="start" />
        <IonLabel className="ion-text-wrap">
          <IonText>
            <p>Gas station</p>
            <h4 className="bold">{gasStation?.name}</h4>
          </IonText>
        </IonLabel>
      </IonItem>
    </IonCard>
  )
}

const Transactions = () => {
  const user = useStore(state => state.user)
  const getGasStationById = useStore(state => state.getGasStationById)
  const { getCustomerById, customers } = useCustomerStore()
  const gasStations = useStore(state => state.gasStations)

  const startOfDayToday = useMemo(() => {
    const startOfDay = DateTime.now().setZone('Africa/Nairobi').startOf('day')
    const startOfDayTimestamp = Timestamp.fromDate(startOfDay.toJSDate())
    return startOfDayTimestamp
  }, [])

  const transactionsQuery = useMemo(() => {
    return user?.id
      ? query(
          transactionsCollectionRef,
          where('createdBy', '==', user.id),
          where('createdAt', '>=', startOfDayToday),
          orderBy('createdAt', 'desc')
        )
      : undefined
  }, [startOfDayToday, user?.id])

  const [transactions, transactionsLoading] = useCollectionData(transactionsQuery, {
    idField: 'id',
  })

  const groupedTransactions = useMemo(() => {
    return _.groupBy(transactions, el => {
      return el?.createdAt?.toDate()?.toLocaleDateString()
    })
  }, [transactions])

  const groupCounts = useMemo(() => {
    return Object.keys(groupedTransactions).map(key => {
      return groupedTransactions[key].length
    })
  }, [groupedTransactions])

  const sortedTransactions = useMemo(() => {
    const flatArray = []
    Object.keys(groupedTransactions).forEach(key => {
      const transactions = groupedTransactions[key]
      flatArray.push(...transactions)
    })
    return flatArray
  }, [groupedTransactions])

  const getTransactionByIndex = useCallback(
    index => {
      return sortedTransactions[index]
    },
    [sortedTransactions]
  )

  const isLoading = useMemo(() => {
    if (transactionsLoading || !customers.length || !gasStations.length) {
      return true
    }
    console.log('All Loaded')
    return false
  }, [transactionsLoading, customers?.length, gasStations?.length])

  if (isLoading) {
    return <IonLoading isOpen={true} message={'Loading your transactions...'} />
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Your Today's Transactions</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="list-wrapper" fullscreen>
        {/* <div className="list-wrapper"> */}
        {transactions.length > 0 && (
          <GroupedVirtuoso
            groupCounts={groupCounts}
            style={{ height: '100%', zIndex: 1000 }}
            groupContent={index => {
              return (
                <IonListHeader mode="ios" lines="none" style={{ backgroundColor: 'white' }}>
                  <IonLabel color="primary">{Object.keys(groupedTransactions)[index]}</IonLabel>
                </IonListHeader>
              )
            }}
            itemContent={(index, groupIndex) => {
              return (
                <TransactionCard
                  key={getTransactionByIndex(index)?.id}
                  transaction={getTransactionByIndex(index)}
                  gasStation={getGasStationById(getTransactionByIndex(index)?.gasStationId)}
                  customer={getCustomerById(getTransactionByIndex(index)?.customerId)}
                />
              )
            }}
          />
        )}
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>No transactions recorded today</IonCardTitle>
          </IonCardHeader>
        </IonCard>
      </IonContent>
    </IonPage>
  )
}

export default Transactions
